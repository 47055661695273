<template>
  <div>
    <d-body-top-bar
      :title="topBarTitle"
      :is-icons-content="false"
    >
      <div slot="tree">
        <label v-if="mobileView" class="inactive-tree mr-1">
          {{ $t('views.playground.your-activities-area') }} /
        </label>
        <label @click="$router.push('/playground')" class="inactive-tree link-area pointer">
          {{ $t('views.playground.all-area') }} /
        </label>
        <label class="bg-doinsport-primary ml-1"> {{ topBarTitle }} </label>
      </div>
      <div slot="icons">
        <steps
          :step="step"
          @on:step-0:selected="step = 0"
          @on:step-1:selected="step = 1"
          @on:step-2:selected="step = 2"
          @on:step-3:selected="step = 3"
        />
      </div>
    </d-body-top-bar>
    <template v-if="!isBusy">
      <step0
        v-if="step === 0"
        :playground="playground"
        :btn-loading="btnLoading"
        :class-container="mobileView ? '' : 'mt-5' "
        @on:step-0:validated="validateStep"
      />
      <step1
        v-if="step === 1"
        :playground="playground"
        :btn-loading="btnLoading"
        :nicheActivitiesSelection="nicheActivitiesSelection"
        :pricesListSelectionStep3="pricesListSelectionStep3"
        :playgroundTimetables="playgroundTimetables"
        :class-container="mobileView ? '' : 'mt-5' "
        @on:step-1:validated="validateStep"
      />
      <step2
        v-if="step === 2"
        :timetable="timetable"
        :playground="playground"
        :calendarsListSelection="calendarsListSelection"
        :class-container="mobileView ? '' : 'mt-5' "
        :playgroundTimetables="playgroundTimetables"
        @on:step-2:validated="onNextStep2"
      />
      <step3
        v-if="step === 3"
        :playground="playground"
        :class-container="mobileView ? '' : 'mt-5' "
        :pricesListSelectionStep3="pricesListSelectionStep3"
        :playgroundTimetables="playgroundTimetables"
        :calendarsListSelection="calendarsListSelection"
      />
    </template>
    <template v-else>
      <div style="padding-top: 10%" class="container-fluid">
        <b-row>
          <b-col align="center">
            <pre-loader
              color="#FF0101"
            />
          </b-col>
        </b-row>
      </div>
    </template>
  </div>
</template>
<script>
import Playground from "@/classes/doinsport/Playground";
import Timetable from "@/classes/doinsport/Timetable";
import {
  getClubPlayground,
  postClubPlayground,
  putClubPlayground
} from "@api/doinsport/services/playground/playground.api";

export default {
  components: {
    Steps: () => import('@views/playground/steps/Menus'),
    Step0: () => import('@views/playground/steps/step-0/Index'),
    Step1: () => import('@views/playground/steps/step-1/Index'),
    Step2: () => import('@views/playground/steps/step-2/Index'),
    Step3: () => import('@views/playground/steps/step-4/Index'),
    PreLoader: () => import('vue-spinner/src/BounceLoader')
  },
  data: () => ({
    value: '',
    step: 0,
    btnLoading: false,
    isBusy: false,
    generalInformationsFormValidation: false,
    nicheActivityValidation: false,
    nicheActivitiesSelection: [],
    pricesListSelectionStep3: [],
    calendarsListSelection: [],
    playgroundTimetables: [],
    timetable: new Timetable(),
    playground: null,
  }),
  computed: {
    topBarTitle() {
      if (this.$route.name === 'playground_new') {
        return this.$t('views.playground.create');
      } else {
        return this.$t('views.playground.update');
      }
    },
    mobileView() {
      return this.$store.getters['layout/getInnerWidth'] >= 991;
    }
  },
  methods: {
    onNextStep2() {
      if (this.calendarsListSelection.length === 0) {
        this.$flash(null, this.$t('views.playground.steps.step-2.empty-calendars'));
      } else {
        let notifications = [];

        for (const calendar of this.calendarsListSelection) {
          if (calendar.calendarStatus) {
            if (calendar.blocks.length === 0) {
              notifications.push({
                title: this.$t('general.actions.notification'),
                text: this.$t('views.playground.steps.step-2.this-calendar') + calendar.name + this.$t('views.playground.steps.step-2.has-no-blocks'),
              });
            }
          }
        }

        if (notifications.length === 0) {
          this.step = 3;
        } else {
          for (const notification of notifications) {
            this.$flash(notification.title, notification.text);
          }
        }
      }
    },
    validateStep() {
      this.step++;
    },
    initPlayground() {
      if (this.$router.currentRoute.params.id) {
        this.isBusy = true;

        getClubPlayground(this.$router.currentRoute.params.id)
          .then((response) => {
            this.playground = new Playground(response.data, {deserialize: true});
            this.playground.bookingCancellationConditionCustomHours = this.playground.bookingCancellationConditionCustomHours / 3600;
            this.playground.minDelayToAllowBooking = this.playground.minDelayToAllowBooking / 3600;
            this.playground.activities = response.data.activities;
            this.playground.linkedPlaygrounds = response.data.linkedPlaygrounds;
            this.playgroundTimetables = response.data.timetables;
          })
          .finally(() => {
            this.isBusy = false;
          })
        ;
      } else {
        this.playground = new Playground();
      }
    }
  },
  async mounted() {
    this.$bus.$on('timetable-reset', () => {
      this.timetable = new Timetable();
    });
    this.$bus.$on('on:playground-reload', () => {
      this.initPlayground();
    });
    this.$bus.$on('step-2:calendar-modal:update', (timetable) => {
      this.timetable = new Timetable(timetable, {serialize: true});
    });
    this.$bus.$on('reset-nicheActivitiesSelection', () => {
      this.nicheActivitiesSelection = [];
    })
    ;
  },
  beforeDestroy() {
    try {
      this.$bus.off('on:playground-reload');
      this.$bus.off('step-2:calendar-modal:update');
      this.$bus.off('timetable-reset');
      this.$bus.off('reset-nicheActivitiesSelection');
    } catch (e) {
    }
  },
  created() {
    this.initPlayground();
  },
}
</script>
